import Layout from '../../components/Layout';
import Seo from '../../components/Seo';

import { graphql, useStaticQuery } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image/dist/src/components/hooks';
import BlogPage, { BlogTextSection, BlogTextTitle } from '../../components/BlogPage';

const HowSplintWorksPage = () => {
  const { placeholderImage }: { placeholderImage: ImageDataLike } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "blog/blog-1.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    `,
  );

  return (
    <Layout>
      <Seo title="So funktioniert Splint" />
      <BlogPage
        image={placeholderImage}
        title={'So funktioniert Splint'}
        subtitle="Unsere FAQs"
        duration={'Lesezeit: 2min'}
      >
        <BlogTextSection>
          Du bist also jemand, der besonders gut über etwas Bescheid wissen will, bevor du damit loslegst? Kein Ding!
          Hier findest du die Fragen, die uns besonders oft gestellt werden.
        </BlogTextSection>

        <BlogTextTitle title="Was ist die Splint App?" />
        <BlogTextSection>
          Unsere App ist eine Plattform, mit der du digitale Anteile an alternativen Anlagen kaufen kannst. Wir wollen
          damit einem breiten Publikum diese Art der Investitionen zugänglich machen. Denn bisher können es sich
          vorwiegend wohlhabende Personen leisten, in Dinge wie Whiskeyfässer oder Uhren zu investieren. Ab €50 kannst
          du Splints erwerben.
        </BlogTextSection>

        <BlogTextTitle title="Was ist ein Splint?" />
        <BlogTextSection>
          Da hast du auf unserer Homepage nicht gut aufgepasst, das haben wir nämlich schon erklärt ;) Ein Splint ist
          eine digitale Einheit, die das Miteigentum an einer physischen Anlage definiert. Beispiel gefällig? Eine Uhr
          mit Wert €10’000 wird in 200 Splints aufgeteilt. Jeder davon hat einen Wert von €50. Du kaufst zwei davon. Dir
          gehört nun also ein Prozent der Uhr.
        </BlogTextSection>

        <BlogTextTitle title="Wie ist der Wert meiner Splints gesichert?" />
        <BlogTextSection>
          Zu jedem Splint gibt es einen Sachwert. Die Anzahl Splints sind pro Anlage vordefiniert und limitiert. Jede
          Anlage ist entsprechend versichert und wird unter optimalen Bedingungen gelagert. Wenn also jemand eine Anlage
          stehlen oder beschädigen würde, bist du abgedeckt. Für eine Wertsteigerung jeder Anlage gibt es aber natürlich
          keine Garantie.
        </BlogTextSection>

        <BlogTextTitle title="Wie erhalte ich Geld für Splints?" />
        <BlogTextSection>
          Zu jeder Anlage gibt es vordefinierte Verkaufsjahre. Wird es erreicht, suchen wir nach der besten
          Verkaufsmöglichkeit. Sobald der Verkauf abgeschlossen ist, schütten wir das Geld an die Splint-Inhaber aus.
          Dann weisst du auch genau, wie stark der Wert deiner Splints gestiegen ist. Du kannst deine Splints aber auch
          in unserem Handelsplatz an andere App-User verkaufen.
        </BlogTextSection>

        <BlogTextTitle title="Wie hoch sind die Investitionskosten?" />
        <BlogTextSection>
          Beim Verkauf einer Anlage wird dir eine Pauschalgebühr von 2 % des Transaktionsvolumens belastet. Darin
          enthalten ist unser Aufwand für Verkäufe auf dem Sekundärmarkt, unsere Kosten für die Aufrechterhaltung dieses
          Marktes sowie unsere Organisation eines Anlagenverkaufs am Vertragsende. Es entstehen keine laufenden Kosten,
          wenn du in Splints investierst. Die variablen Kosten für die Lagerung und Versicherung sind bereits im
          Splint-Preis von €50 enthalten. Alle Details dazu findest du im Prospekt der jeweiligen Anlage (Factsheet).|
        </BlogTextSection>
        <BlogTextSection>
          Um neue Anlageklassen zu eröffnen, erheben wir jährlich einen Forschungsbeitrag von €3 pro Anlageklasse. Er
          geht in unseren Forschungs- und Entwicklungsfonds, um euch noch bessere Investitionsmöglichkeiten zu bieten.
          Dieser Beitrag wird nur beim ersten Kauf erhoben und nicht abgezogen, falls du inaktiv bist und du dich
          entschliessen solltest, keine weiteren Splints zu erwerben.
        </BlogTextSection>
        <BlogTextSection>
          Bei Splint Invest fallen keine Depotkosten oder anderweitige, versteckte Gebühren an. Es gibt auch keine
          Auszahlungsgebühr, wenn du dein Geld aus Splint zurück transferierst.
        </BlogTextSection>

        <BlogTextTitle title="Noch Fragen?" />
        <BlogTextSection>
          Kein Ding! Uns ist Transparenz sehr wichtig, daher antworten wir gerne auf deine brennenden Fragen. Schreib
          uns einfach eine E-Mail an <a href="mailto:info@splintinvest.com">info@splintinvest.com</a> und wir melden uns
          bei dir.
        </BlogTextSection>
      </BlogPage>
    </Layout>
  );
};

export default HowSplintWorksPage;
